@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/spacing';

.error-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  &__content {
    background-color: light.$surface-primary-default;
    border: 1px solid light.$ge-divider-default;
    border-radius: 8px;
    padding: spacing.$xxl spacing.$l;
    margin: spacing.$m;
    max-width: 500px;
    align-self: center;
    text-align: center;

    @media (min-width: 600px) {
      padding: spacing.$xxl;
    }
  }

  &__title {
    margin-bottom: spacing.$m;
  }

  &__text {
    margin-bottom: spacing.$l;
  }
}
